import React, { useState } from "react";
import { auth } from "../firebaseConfig";
import { Link } from "react-router-dom";
import { BlueContainer, Contour, FormContainer } from "../Components/signPage/Form";
import { BlueButton, GoogleButton } from "../Components/signPage/Button";
import { Input } from "../Components/signPage/Input";
import { Description, ExtraMessage, LogoTitle, SmallTitle } from "../Components/signPage/TextStyle";
import styled from "styled-components";
import { GoogleAuthProvider, createUserWithEmailAndPassword, sendEmailVerification, signInWithRedirect } from "firebase/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const ErrorMessage = styled.div`
    width: 100%;
    padding: 8px;
    color: red;
    background: #F3F4F6;
    font-size: 13px;
    font-weight: 400;
    display: ${props => props.error ? "flex" : "none"};
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 7px;
`

const SignUp = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const history = useHistory();

    const actionCodeSettings = {
        url: `https://our-expense.com/`,
        handleCodeInApp: true,
    };
    const onChange = (event) => {
        const { target: { name, value } } = event;
        if (name === "email") {
            setEmail(value);
        } else if (name === "password") {
            setPassword(value);
        }
    }
    const onEmailSubmit = async (e) => {
        e.preventDefault();
        try {
            await createUserWithEmailAndPassword(auth, email, password)
            await sendEmailVerification(auth.currentUser, actionCodeSettings);

        } catch (error) {
            switch (error.code) {
                case 'auth/email-already-in-use':
                    setErrorMessage("⚠️ there already exists an account with the given email address.");
                    break;
                case "auth/operation-not-allowed":
                    setErrorMessage("⚠️ email/password accounts are not enabled ");
                    break;
                case "auth/weak-password":
                    setErrorMessage("⚠️ The password is too weak. please more than 6 letters.");
                    break;
                case "auth/invalid-email":
                    setErrorMessage("⚠️ the email address is not valid.");
                    break;
                default:
                    setErrorMessage("⚠️ Too many try in short time. please try later.");
            }
            setIsError(true);
        } finally {
            if (!isError) {
                history.push("/")
            }
        }
    }
    const onGoogleClick = async () => {
        const provider = new GoogleAuthProvider();

        try {
            await signInWithRedirect(auth, provider);
            console.log("Google Sign up complete");

        } catch (error) {
            const errorMessage = error.message;
            console.log(errorMessage);
        }
    }

    return (
        <BlueContainer>
            <FormContainer>
                <LogoTitle marginTop="38px" marginBottom={"24px"} />
                <SmallTitle>
                    Sign up quickly with
                </SmallTitle>
                <GoogleButton onClick={onGoogleClick} marginBottom="16px" />
                <Contour />
                <ErrorMessage error={isError}>
                    {errorMessage}
                </ErrorMessage>
                <SmallTitle>Create an account</SmallTitle>
                <form onSubmit={onEmailSubmit}>
                    <Input name="email" type="email"
                        value={email}
                        placeholder="Email address"
                        onChange={onChange}
                        error={isError}
                    />
                    <Input name="password" type="password"
                        value={password}
                        placeholder="Password (Use 8 or more characters)"
                        onChange={onChange}
                        error={isError}
                    />
                    <Description>
                        By signing up, you accept
                        Our Expense’s<br /> Terms of Service and Privacy Policy
                    </Description>
                    <BlueButton type="submit" title="Create" marginBottom="43px" />
                </form>
            </FormContainer>
            <ExtraMessage>
                Already have an account?
                <Link to="/"> <span>Sign in</span></Link>
            </ExtraMessage>
        </BlueContainer>
    );
}

export default SignUp;