import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainContainer from "../Components/MainContainer";
import CalendarIcon from "../asset/image/Calendar.png"
import AddIcon from "../asset/image/add-icon.png"
import { format } from "date-fns";
import { DayPicker } from "react-day-picker";
import 'react-day-picker/dist/style.css';
import BackButton from "../Components/BackButton";
import ShadowScreen from "../Components/ShadowScreen";
import { db } from "../firebaseConfig";
import { useRecoilValue } from "recoil";
import { categoryState, userState } from "../atoms";
import CategoryAddBox from "./CategoryAddBox";
import { useHistory } from "react-router-dom";

const WholeContainer = styled.div`
    width: 100%;
    position: relative;

`;

const AddExpenseContainer = styled.div`
    margin-bottom: 24px;
`
const AmountContainer = styled.div`
margin-bottom: 21px;
span {
    font-size: 42px;
    font-weight: 700;
    margin-right: 8px;
}
`
const InputAmount = styled.input`
    outline: none;
    border: hidden;
    font-size: 42px;
    font-weight: 700;
    height: 40px;
    ::placeholder{
        color: ${props => props.theme.palette.black200};
        font-size: 35px;
    }
    &:focus::placeholder{
        opacity: 0;
    }
`
const DateContainer = styled.div`
    margin-bottom: 22px;
    display: flex;
    position: relative;
`;
const CalendarContainer = styled.div`
    width: 50%;
    background-color: white;
    z-index: 2;
    position: absolute;
    top: 25px;
    left: 85px;
    border: 1px solid;
    border-radius: 8px;
`;
const Title = styled.span`
    font-size: 18px;
    font-weight: 700;
    margin-right: 43px;
`;
const SelectedDate = styled.span`
    margin-right: 15px;
    font-size: 18px;
    font-weight: 400;
`
const CalenderButton = styled.div`
    background-image: url(${CalendarIcon});
    width: 18px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`
const NoteContainer = styled.div`
    margin-bottom: 20px;
`
const Note = styled.input`
    border: hidden;
    outline: none;
    font-size: 18px;
    ::placeholder{
        color: ${props => props.theme.palette.black200};
        font-size: 18px;
        font-weight: 400;
    }
    &:focus::placeholder{
        opacity: 0;
    }
`
const CategoryContainer = styled.div`
    margin-bottom: 24px;
`;
const CategoryList = styled.ul`
    margin-top: 20px;
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
`
const CategoryItem = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    padding-right: 12px;
    background-color: ${props => props.selected ?
        props.theme.palette.black800 : "white"
    };
    color: ${props => props.selected ?
        "white" : "black"};
    border-radius: 50px;
`
const CategoryIcon = styled.div`
    background-color: #F1F1F1;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;
    margin-right: 8px;
`
const AddCategory = styled.div`
    background-image: url(${AddIcon});
    background-size: cover;
    background-position: center;
    width: 15px;
    height: 15px;
`;
const CategoryTitle = styled.span`
    font-size: 15px;
    font-weight: 400;
`
const AddCategoryItem = styled(CategoryItem)`
    cursor: pointer;
`
const ButtonContainer = styled.div`
    display: flex;
    gap: 20px;
`;
const AddButton = styled.div`
    background-color: ${props => props.theme.palette.blue};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
`
const CancelButton = styled(AddButton)`
    background-color: ${props => props.theme.palette.black200};
    color: ${props => props.theme.palette.black800};
    font-weight: 400;
`

const InputExpenseFormat = () => {
    const [amount, setAmount] = useState("");
    const today = format(new Date(), "d LLLL Y");
    const [selected, setSelected] = useState(new Date());
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [note, setNote] = useState("");
    const [isShadow, setIsShadow] = useState(false)
    const [isPopup, setIsPopup] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState("");
    const categoryList = useRecoilValue(categoryState);
    const userData = useRecoilValue(userState);
    const history = useHistory();

    // useEffect(() => {
    //     const categoryRef = db.collection(db.db, "books", userData.bookList[0], "categories");
    //     const unSubscribeCategory = db.onSnapshot(categoryRef, (snapshot) => {
    //         const categoryData = snapshot.docs.map((doc) => ({
    //             id: doc.id,
    //             ...doc.data(),
    //         }))
    //         setCategoryList(categoryData);
    //     })
    //     return () => { unSubscribeCategory(); }
    // }, []);

    const onClickAdd = async () => {
        const expenseRef = db.collection(db.db, "books", userData.bookList[0], "expense");
        await db.addDoc(expenseRef, {
            createAt: new Date(),
            amount: parseFloat(amount),
            note: note,
            date: selected,
            category: selectedCategoryId,
            creator: userData.uid,
        })
        history.goBack();
    }

    const onChangeAmount = (event) => {
        const { target: { value } } = event;

        let formattedValue;

        if (value.includes('.') && value.lastIndexOf('.') !== value.indexOf('.')) {
            formattedValue = value.slice(0, -1)

            setAmount(formattedValue);
            return;
        }

        if (value === '.') {
            // 첫 번째 입력이 소수점인 경우 앞에 0 추가
            setAmount("0.");
            return;
        }

        // 숫자와 소수점 이외의 문자 제거
        const numericValue = value.replace(/[^0-9.]/g, '');

        if (numericValue.includes(".")) {
            const decimalParts = numericValue.split('.');
            if (decimalParts[1].length > 2) {
                formattedValue = numericValue.slice(0, -1);
                setAmount(formattedValue);
                return;
            }
        }
        setAmount(numericValue);
    }

    const onCalenderButton = (event) => {
        event.stopPropagation();
        setIsPopperOpen(true);
    };
    const closePopper = () => {
        setIsPopperOpen(false);
    };

    const handleDaySelect = (event, date) => {

        setSelected(date);
        if (date) {
            closePopper();
        }
    };

    const onChangeNote = (e) => {
        const { target: { value } } = e;
        setNote(value);
    }
    const onCancelClick = (e) => {
        e.stopPropagation();
        setIsShadow(true);
    }

    const onAddCategoryButton = () => {
        setIsPopup(true);
    }
    const onClickExecute = () => {

        history.goBack();
    }


    return (
        <WholeContainer onClick={closePopper}>
            <ShadowScreen isShadow={isShadow} setIsShadow={setIsShadow}
                message={"Do you want to discard changes?"}
                excute={"Discard"}
                onClickExecute={onClickExecute}
            />
            <CategoryAddBox isPopup={isPopup} setIsPopup={setIsPopup} />
            <MainContainer>
                <BackButton title={"Back"} />
                <AddExpenseContainer>
                    <AmountContainer>
                        <span>€</span>
                        <InputAmount type="text" min={0} placeholder="Enter your expense" maxLength={10} value={amount} onChange={onChangeAmount} />
                    </AmountContainer>

                    <DateContainer>
                        <Title>Date</Title>
                        <SelectedDate>
                            {format(selected, "d LLLL Y")}
                            {format(selected, "d LLLL Y") === today ? "(Today)" : null}
                        </SelectedDate>
                        <CalenderButton onClick={onCalenderButton} />
                        {isPopperOpen && (
                            <CalendarContainer onClick={(e) => { e.stopPropagation() }}>
                                <DayPicker
                                    initialFocus={isPopperOpen}
                                    mode="single"
                                    defaultMonth={selected}
                                    selected={selected}
                                    onSelect={handleDaySelect}
                                />
                            </CalendarContainer>
                        )}
                    </DateContainer>

                    <NoteContainer>
                        <Title>Note</Title>
                        <Note
                            type="text"
                            placeholder="Optional"
                            maxLength={50}
                            value={note}
                            onChange={onChangeNote}
                        />
                    </NoteContainer>

                    <CategoryContainer>
                        <Title>Category</Title>
                        <CategoryList>
                            {categoryList && categoryList.length > 0 && (
                                categoryList.map((category) => (
                                    <CategoryItem onClick={() => { setSelectedCategoryId(category.id) }} key={category.id} selected={category.id === selectedCategoryId}  >
                                        <CategoryIcon>
                                            {category.emoji}
                                        </CategoryIcon>
                                        <CategoryTitle>
                                            {category.title}
                                        </CategoryTitle>
                                    </CategoryItem>
                                ))
                            )}
                            <AddCategoryItem onClick={onAddCategoryButton}>
                                <CategoryIcon>
                                    <AddCategory />
                                </CategoryIcon>
                                <CategoryTitle>
                                    Add
                                </CategoryTitle>
                            </AddCategoryItem>
                        </CategoryList>
                    </CategoryContainer>

                    <ButtonContainer>
                        <CancelButton onClick={onCancelClick}>Cancel</CancelButton>
                        <AddButton onClick={onClickAdd}>Add</AddButton>
                    </ButtonContainer>
                </AddExpenseContainer>
            </MainContainer>
        </WholeContainer>
    )
}

export default InputExpenseFormat;