import React from "react";
import InputExpenseFormat from "../Components/InputExpenseFormat";

const EditExpense = () => {

    return (
        <InputExpenseFormat />
    );
}

export default EditExpense;