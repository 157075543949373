import React, { useState } from "react";
import { BlueContainer, FormContainer } from "../Components/signPage/Form";
import { Description, LogoTitle, MediumTitle } from "../Components/signPage/TextStyle";
import { Input } from "../Components/signPage/Input";
import { BlueButton } from "../Components/signPage/Button";
import { useHistory } from "react-router-dom";

import {sendEmailVerification, getAuth} from "firebase/auth";
import styled from "styled-components";
import { userState } from "../atoms";
import { useRecoilValue } from "recoil";


const Container = styled(BlueContainer)`
    height: calc(100vh - 105px);
`

const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for
    // this URL must be whitelisted in the Firebase Console.
    url: 'https://our-expense.com/',
    // This must be true for email link sign-in.
    handleCodeInApp: true,
};
const auth = getAuth();


const EmailVerification = () => {
    const currentUser = useRecoilValue(userState);
    console.log(currentUser, "수정");
    const [email, setEmail] = useState(currentUser.email);
    const history = useHistory();

    const onChange = (e) => {
        const { target: { value } } = e;
        setEmail(value);
    }

    const onSendEmailClick = async () => {
        await sendEmailVerification(auth.currentUser, actionCodeSettings)
            .then(() => {
                console.log("Email Sent");
                history.push("/")
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            });
    }

    return (
        <Container>
            <FormContainer>
                <LogoTitle marginTop={"52px"} marginBottom={"30px"} />
                <MediumTitle>Email Verification</MediumTitle>
                <Description style={{ textAlign: "left", marginBottom: "31px" }}>
                    Please check your email first, and if you haven't <br />
                    received a verification email, enter the correct<br />
                    email and click the "Send" button.
                </Description>
                <Input name="email" type="email" value={email} onChange={onChange} placeholder="Email address"
                    style={{ marginBottom: "24px" }}
                />
                <BlueButton marginBottom={"52px"} title={"Send"} onClick={onSendEmailClick} />
            </FormContainer>

        </Container>
    );
}

export default EmailVerification;
