import React from "react";
import Loader from "../Components/Loader";
import { auth, db } from "../firebaseConfig";
import ResetPassword from "./ResetPassword";
import { v4 as uuidv4 } from 'uuid';
import { applyActionCode, signOut } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

const EmailHandler = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1

    const mode = queryParams.get('mode');
    // Get the one-time code from the query parameter.
    const actionCode = queryParams.get('oobCode');
    // // (Optional) Get the continue URL from the query parameter if available.
    // const continueUrl = queryParams.get('continueUrl');
    // // // (Optional) Get the language code if available.
    // const lang = queryParams.get('lang') || 'en';

    // Handle the user management action.
    switch (mode) {
        case 'resetPassword':
            // Display reset password handler and UI.

            return (<ResetPassword auth={auth} actionCode={actionCode} />);

        case 'recoverEmail':
            // Display email recovery handler and UI.
            // handleRecoverEmail(auth, actionCode, lang);
            break;
        case 'verifyEmail':
            // Display email verification handler and UI.
            const afterVerify = async (actionCode) => {
                try {
                    await applyActionCode(auth, actionCode);
                    // Email address has been verified.
                    const bookId = uuidv4();
                    // Create user document
                    await setDoc(doc(db, "users", auth.currentUser.uid), {
                        email: auth.currentUser.email,
                        uid: auth.currentUser.uid,
                        displayName: auth.currentUser.displayName,
                        bookList: [bookId],
                        emailVerified: true,
                    });
                    //Main book
                    await setDoc(doc(db, "books", bookId), {
                        title: "Account Book",
                        ownerId: auth.currentUser.uid,
                        shareWith: [],
                    })
                    //default Category
                    await setDoc(doc(db, "books", bookId, "categories", "Supermarket"), {
                        title: "Suermarket",
                        emoji: "🛒",
                    })
                    //start month
                    await setDoc(doc(db, "books", bookId, "months", month > 9 ? `${year}${month}` : `${year}0${month}`), {
                        total: 0,
                        expenseList: [],
                    })
                    // Display a confirmation message to the user
                    alert("Confirm your Email! Login please");
                    // Sign out the user
                    signOut(auth);
                } catch (error) {
                    // Code is invalid or expired. Ask the user to verify their email address again.
                    console.log(error);
                }
            };


            // Call the function passing the actionCode
            afterVerify(actionCode);
            // authService.applyActionCode(authService.auth, actionCode).then((resp) => {
            //     // Email address has been verified.
            //     //create user document
            //     dbService.setDoc(dbService.doc(dbService.db, "users", authService.auth.currentUser.uid), {
            //         email: authService.auth.currentUser.email,
            //         uid: authService.auth.currentUser.uid,
            //         displayName: authService.auth.currentUser.displayName,
            //     })
            //     // TODO: Display a confirmation message to the user.
            //     alert("Confirm your Email! Login please");
            //     // You could also provide the user with a link back to the app.
            //     // TODO: If a continue URL is available, display a button which on
            // }).then(() => {
            //     authService.signOut(authService.auth)
            // }).catch((error) => {
            //     // Code is invalid or expired. Ask the user to verify their email address
            //     // again.
            // });

            break;
        default:
        // Error: invalid mode.
    }

    return (<Loader />)

}
export default EmailHandler;
