import styled from "styled-components";


export const BlueContainer = styled.div`
    background-color: ${(props) => props.theme.palette.blue};
    width:100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

export const FormContainer = styled.div`
    background-color: ${(props) => props.theme.palette.white};
    width: 490px;
    padding: 0 85px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
`;

const ContourBox = styled.div`
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    height: 30px;
    span {
        display: block;
        margin: 0 20px;
        color: ${props => props.theme.palette.black400};
    }
`
const ContourLine = styled.div`
    flex-grow: 1;
    border-bottom: 1px solid ${props => props.theme.palette.black400};
`;

export const Contour = () => (
    <ContourBox>
        <ContourLine />
        <span>or</span>
        <ContourLine />
    </ContourBox>
);