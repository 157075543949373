import React, { useEffect, useState } from "react"
import AppRouter from "./Components/Router";
import { db, auth } from "./firebaseConfig";
import Loader from "./Components/Loader";
import { doc, getDoc } from "firebase/firestore";
import { useRecoilState } from "recoil";
import { userState } from "./atoms";
import { onAuthStateChanged } from "firebase/auth";



function App() {
  const [init, setInit] = useState(false);
  const [userData, setUserData] = useRecoilState(userState);

  const getUserDB = async (uid) => {
    const docRef = doc(db, "users", uid);
    const userDbSnap = await getDoc(docRef);
    setUserData(userDbSnap.data());
    setInit(true);
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      console.log("유저가 바꼈습니다.")
      if (user) {
        const { emailVerified, uid } = user;
        if (emailVerified === true) {
          getUserDB(uid);
        } else {
          setUserData(user);
          console.log(user.emailVerified)
          setInit(true)
        }
      } else {
          setUserData(null);
          setInit(true);
      }
  })
    
  }, [])

  return (
    <div className="App">
      {init ? <AppRouter isLogIn={userData} /> : <Loader />}
    </div>
  );

}

export default App;
