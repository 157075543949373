export const theme = {
    palette: {
        blue: "#2400FF",
        white: "#FFFFFF",
        red: "#FF0000",
        green: "#00C851",
        black900: "#111827",
        black800: "#1F2937",
        black700: "#374151",
        black600: "#4B5563",
        black500: "#6B7280",
        black400: "#94A3B8",
        black300: "#CBD5E1",
        black200: "#E5E7EB",
        black100: "#F3F4F6",
        black50: "#F8FAFC",
    }
};