import React from "react";
import styled from "styled-components";
import { BlueContainer } from "../Components/signPage/Form";

const Message = styled.h1`
    color: white;
    font-size: 40px;
    margin-bottom: 40px;
`;

const Description = styled.p`
    color: white;
    font-size: 20px;
    text-align: center;
`;
const Container = styled(BlueContainer)`
    height: calc(100vh - 105px);
`

const CheckVerificationEmail = () => {

    return (

        <Container>
            <Message>
                "Check your Email. Thanks!"
            </Message>
            <Description>
                Please comfirm that you want to use this as<br />
                your account email address. Once It's done you will be able to start.
            </Description>
        </Container>
    )
}
export default CheckVerificationEmail;