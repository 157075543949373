import React from "react";
import InputExpenseFormat from "../Components/InputExpenseFormat";


const AddExpense = () => {
    return (
        <InputExpenseFormat />
    )
}

export default AddExpense;