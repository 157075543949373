import React, { useState } from "react";
import styled from "styled-components";
import CancelBoxIcon from "../asset/image/cancel-icon.png"
import EmojiPicker from "./EmojiPicker";
import { db } from "../firebaseConfig";
import { useRecoilValue } from "recoil";
import { userState } from "../atoms";

const ShadowContainer = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(0,0,0,0.4);
    display: ${props => props.isPopup ? "flex" : "none"};
    justify-content: center;
    align-items: center;
`
const CategoryBox = styled.div`
    width: 350px;
    position: relative;
    background-color: white;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
`;
const CancelIcon = styled.div`
    background-image: url(${CancelBoxIcon});
    background-size: cover;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
`
const BoxTitle = styled.span`
    font-size: 24px;
    font-weight: 700;
    color: ${props => props.theme.palette.black900};
    text-align: center;
    margin-bottom: 24px;
`;
const EmojiSelector = styled.div`
    width: 100px;
    height: 100px;
    background-color: #F1F1F1;
    border-radius: 50%;
    font-size: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 24px;
    position: relative;
`;
const CategoryTitleInput = styled.input`
    font-size: 20px;
    margin-bottom: 24px;
    border: none;
    outline: none;
    text-align: center;
    ::placeholder{
        color: ${props => props.theme.palette.black300};
        font-size: 20px;
        font-weight: 400;
    }
    &:focus::placeholder{
        opacity: 0;
    }
`;
const AddButton = styled.div`
    cursor: pointer;
    width: 100%;
    padding: 16px;
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    color: white;
    background-color: ${props => props.theme.palette.blue};
    border-radius: 8px;
`

const CategoryAddBox = ({ isPopup, setIsPopup, }) => {

    const userData = useRecoilValue(userState);

    const [emoji, setEmoji] = useState("+");
    const [isEmojiSelector, setIsEmojiSelector] = useState(false);
    const [categoryTitle, setCategoryTitle] = useState("");

    const onClickCancel = () => {
        setIsPopup(false);
        setEmoji("+")
        setCategoryTitle("");
    }
    const onClickEmoji = () => {
        setIsEmojiSelector(true);
    }
    const onChangeTitle = (e) => {
        const { target: { value } } = e;
        setCategoryTitle(value);
    }
    const onSubmit = async (e) => {
        await db.addDoc(db.collection(db.db, "books", userData.bookList[0], "categories"), {
            title: categoryTitle,
            emoji: emoji,
        });
        setIsPopup(false);
        setEmoji("+")
        setCategoryTitle("");
    }

    return (
        <ShadowContainer isPopup={isPopup}>
            <CategoryBox>
                <CancelIcon onClick={onClickCancel} />
                <BoxTitle>Add category</BoxTitle>
                <EmojiSelector onClick={onClickEmoji}>
                    {emoji}
                    <EmojiPicker isEmojiSelector={isEmojiSelector}
                        setIsEmojiSelector={setIsEmojiSelector}
                        setEmoji={setEmoji}
                    />
                </EmojiSelector>

                <CategoryTitleInput
                    type="text"
                    placeholder="Category name"
                    value={categoryTitle}
                    onChange={onChangeTitle}
                />
                <AddButton onClick={onSubmit}>Add</AddButton>
            </CategoryBox>
        </ShadowContainer>
    );
}

export default CategoryAddBox;