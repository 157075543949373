import React from "react";
import styled, { keyframes } from "styled-components";

const spinner1 = keyframes`
    to {
        transform: rotate(360deg);
      }
`
const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`
const LoadingCircle = styled.div`
    height: 80px;
    width: 80px;
    background: transparent;
    border-radius: 50%;
     border: 2px solid #1D9AF2;
    border-top: 3px solid #fff;
    animation: ${spinner1} 600ms linear infinite;
`

const Loader = () => (
    <Container>
        <LoadingCircle />
    </Container>
)

export default Loader;