import { onAuthStateChanged } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { auth } from "../firebaseConfig";

const AuthContext = createContext({
    user: null,
})


export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            console.log("onAuthState에서 유저가 바꼈습니다.")
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        })
    }, [])

    return (
        <AuthContext.Provider value={{ user }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext