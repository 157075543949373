import React, { useState } from "react";
import { BlueContainer, FormContainer } from "../Components/signPage/Form";
import { Description, LogoTitle, MediumTitle } from "../Components/signPage/TextStyle";
import { Input } from "../Components/signPage/Input";
import { BlueButton } from "../Components/signPage/Button";
import { auth } from "../firebaseConfig";


const ResetPassword = ({ auth, actionCode }) => {

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const onClick = () => {
        // Localize the UI to the selected language as determined by the lang
        // parameter.

        // Verify the password reset code is valid.
        auth.verifyPasswordResetCode(auth, actionCode).then((email) => {

            // TODO: Show the reset screen with the user's email and ask the user for
            // the new password.
            if (password === confirmPassword) {
                const newPassword = password;
                // Save the new password.
                auth.confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
                    // Password reset has been confirmed and new password updated.

                    // TODO: Display a link back to the app, or sign-in the user directly
                    // if the page belongs to the same domain as the app:
                    auth.signInWithEmailAndPassword(auth, email, newPassword);

                }).catch((error) => {
                    // Error occurred during confirmation. The code might have expired or the
                    // password is too weak.
                });
            } else {
                throw Error;
            }
        }).catch((error) => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
        });
    }
    const onChange = (e) => {
        const { target: { name, value } } = e;
        if (name === "password") {
            setPassword(value);
        } else if (name === "confirm-password") {
            setConfirmPassword(value);
        }
    }

    return (
        <BlueContainer>
            <FormContainer>
                <LogoTitle marginTop={"42px"} marginBottom={"30px"} />
                <MediumTitle>
                    Reset Password
                </MediumTitle>
                <Description style={{ textAlign: "left" }}>
                    Enter the email address you used when you<br />
                    joined and we’ll send you instructions to reset<br />
                    your password.
                </Description>
                <Input name="password" type="password" placeholder="New password"
                    onChange={onChange}
                    value={password}
                    style={{ marginBottom: "20px" }} />
                <Input name="confirm-password" type="password" placeholder="Confirm new password"
                    onChange={onChange}
                    value={confirmPassword}
                    style={{ marginBottom: "24px" }} />
                <BlueButton marginBottom={"42px"} title={"Reset"} onClick={onClick} />
            </FormContainer>
        </BlueContainer>
    );
}

export default ResetPassword;