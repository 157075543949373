import styled from "styled-components";

const MainContainer = styled.div`
    width: 100%;
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 24px;
    margin-bottom: 62px;
    position: relative;
`;

export default MainContainer;