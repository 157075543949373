import React, { useEffect, useState } from "react";
import styled from "styled-components";
import AddIcon from "../asset/image/add-icon.png";
import EditIcon from "../asset/image/edit-icon.png";
import CategoryAddBox from "./CategoryAddBox";
import { Link } from "react-router-dom";


const CategoryContainer = styled.div`
    width: 100%;
`
const CategoryList = styled.div`
    margin-bottom: 24px;
`
const CategoryItemContainer = styled.div`
`;
const CategoryItem = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:16px 20px;
    gap: 15px;
    border: 2px solid ${props => props.theme.palette.black900};
    border-radius: 12px;
    margin-bottom: 12px;
`
const CategoryIcon = styled.div`
    background-color: #F1F1F1;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;
`;
const CategoryTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
`;
const CategoryAmount = styled.div`
    flex-grow: 1;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    text-align: right;
`
const CategoryBottom = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 66px;
`;
const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
        font-size: 18px;
        font-weight: 400;
    }
`;
const IconContainer = styled.div`
    width: 40px;
    height: 40px;
    background-color: #f1f1f1;
    border-radius: 50%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
`;
const Icon = styled.img`
    width: 15px;
    height: 15px;
`



const CategoryView = ({ categoryList, groupByCategory }) => {

    const [isPopup, setIsPopup] = useState(false);
    const onAddCategoryButton = () => {
        setIsPopup(true);
    }


    return (
        <CategoryContainer>
            <CategoryAddBox isPopup={isPopup} setIsPopup={setIsPopup} />
            <CategoryList>
                <CategoryItemContainer>
                    {categoryList && categoryList.length > 0 && categoryList.map((category) => (
                        <Link to="category-detail" key={category.id}>
                            <CategoryItem>
                                <CategoryIcon>{category.emoji}</CategoryIcon>
                                <CategoryTitle>{category.title}</CategoryTitle>
                                <CategoryAmount>€{category.expenseList ? category.expenseList.reduce((result, expense) => {
                                    result += parseFloat(expense.amount);
                                    return result;
                                }, 0)

                                    : "0"}
                                </CategoryAmount>
                            </CategoryItem>
                        </Link>
                    ))}
                </CategoryItemContainer>

            </CategoryList>
            <CategoryBottom>
                <ButtonContainer onClick={onAddCategoryButton}>
                    <IconContainer><Icon src={AddIcon} /></IconContainer>
                    <span>Add category</span>
                </ButtonContainer>
                <Link to="editcategory">
                    <ButtonContainer>
                        <IconContainer><Icon src={EditIcon} /></IconContainer>
                        <span>Edit category</span>
                    </ButtonContainer>
                </Link>

            </CategoryBottom>
        </CategoryContainer >
    )
}

export default CategoryView;