import React from "react";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import Home from "../Routes/Home";
import SignIn from "../Routes/SignIn";
import SignUp from "../Routes/SignUp";
import Navigation from "./Navigation";
import ResetPassword from "../Routes/ResetPassword";
import ForgotPassword from "../Routes/ForgotPassword";
import CheckVerificationEmail from "../Routes/CheckVerificationEmail";
import EmailHandler from "../Routes/EmailHandler";
import AddExpense from "../Routes/AddExpense";
import EditCategory from "../Routes/EditCategory";
import CategoryDetail from "../Routes/CategoryDetail";
import ExpenseDetail from "../Routes/ExpenseDetail";
import EditExpense from "../Routes/EditExpense";
import EmailVerification from "../Routes/EmailVerification";



const AppRouter = ({ isLogIn }) => {

    return (
        <Router>
            {isLogIn && <Navigation />}
            <Switch>
                {isLogIn ? (
                    <>
                        <Route exact path={"/"}>
                            {isLogIn.emailVerified ? <Home /> : <EmailVerification />}
                        </Route>
                        <Route exact path={"/addexpense"}>
                            <AddExpense />
                        </Route>
                        <Route exact path={"/editcategory"}>
                            <EditCategory />
                        </Route>
                        <Route exact path={"/edit-expense"}>
                            <EditExpense />
                        </Route>
                        <Route strict path={"/emailhandler"} >
                            <EmailHandler />
                        </Route>
                        <Route exact path={"/category-detail"}>
                            <CategoryDetail />
                        </Route>
                        <Route exact path={"/expense-detail"}>
                            <ExpenseDetail />
                        </Route>
                        <Redirect from="*" to="/" />
                    </>
                ) : (
                    <>
                        <Route exact path={"/"}>
                            <SignIn />
                        </Route>
                        <Route path={"/signup"}>
                            <SignUp />
                        </Route>
                        <Route exact path={"/forgotpassword"} >
                            <ForgotPassword />
                        </Route>
                        <Route exact path={"/resetpassword"}>
                            <ResetPassword />
                        </Route>
                        <Route exact path={"/emailverify"}>
                            <EmailVerification />
                        </Route>
                        <Route exact path={"/checkemail"}>
                            <CheckVerificationEmail />
                        </Route>
                        <Route path={"*"}>
                            <Redirect to="/" />
                        </Route>
                    </>
                )
                }
            </Switch>
        </Router>
    )
}

export default AppRouter;
