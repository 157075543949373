import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MainContainer from "../Components/MainContainer";
import CategoryView from "../Components/CategoryView";
import ListViewIcon from "../asset/image/ListIcon.png";
import CategoryViewIcon from "../asset/image/category-view-icon.png"
import ListView from "../Components/ListView";
import { db } from "../firebaseConfig";
import { useRecoilState, useRecoilValue } from "recoil";
import { categoryState, userState } from "../atoms";
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";

const Header = styled.div`
    width: 100%;
`;
const Period = styled.div`
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 4px;
`
const TotalAmount = styled.div`
    font-size: 42px;
    font-weight: 700;
    margin-bottom: 24px;
`
const AddExpense = styled.div`
    background-color: ${props => props.theme.palette.blue};
    border-radius: 8px;
    border: hidden;
    padding: 16px 0px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: white;
    width: 100%;
`

const ViewContainer = styled.div`
    width: 100%;
    margin-top: 25px;

`
const ViewSelectorContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
`;
const ViewTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
`
const ViewSelector = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`
const SelectorIcon = styled.img`
    width: 16px;
    height: 16px;
    margin-right: 12px;
`;
const SelectorTitle = styled.span`
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
`
//select book id 추가 dependency 에도 추가
const Home = () => {
    const [isCategory, setIsCategory] = useState(false);
    const [expenseData, setExpenseData] = useState([]);
    const [groupByCategory, setGroupByCategory] = useState([]);

    //Date calculate for query
    const now = new Date();
    const monthDescribe = now.toLocaleString('en-US', { month: 'short' });
    const year = now.getFullYear();
    const month = now.getMonth() + 1
    const nowDate = `${year}-${month}`;
    const lastDay = new Date(year, month, 0).getDate()


    const userData = useRecoilValue(userState);
    const [categoryList, setCategoryList] = useRecoilState(categoryState);

    const getMonth = async () => {
        const monthRef = doc(db, "books", userData.bookList[0], "months", "202309");
        const docSnap = await getDoc(monthRef);
        console.log(docSnap.exists());
        if (docSnap.exists()) {

            console.log("Document data:", docSnap.data());
        } else {
            console.log("No such document!");
        }
    }

    useEffect(() => {
        getMonth();
        // const expenseQuery = query(
        //     expenseRef,
        //     where("date", ">=", new Date(`${nowDate}-01`)),
        //     where("date", "<=", new Date(`${nowDate}-${lastDay}`)),

        // )

        // const unSubscribeExpense = onSnapshot(expenseQuery, (snapshot) => {
        //     const groupByDate = snapshot.docs.reduce((result, doc) => {
        //         const data = {
        //             id: doc.id,
        //             ...doc.data(),
        //         }
        //         const date = data.date.toDate().toLocaleDateString("en-US");

        //         if (!result[date]) {
        //             result[date] = []
        //         }
        //         result[date].push(data);
        //         return result
        //     }, {});

        //     //sorting by date
        //     const sortedData = Object.entries(groupByDate).sort((a, b) => new Date(b[0]) - new Date(a[0]));
        //     setExpenseData(sortedData);

        //     const groupByCategory = snapshot.docs.reduce((result, doc) => {
        //         const data = {
        //             id: doc.id,
        //             ...doc.data(),
        //         }
        //         if (!result[data.category]) {
        //             result[data.category] = []
        //         }
        //         result[data.category].push(data);
        //         return result;
        //     }, {});
        //     setGroupByCategory(groupByCategory);
        //     console.log("Expense Data change")
        // })


        // return () => {
        //     unSubscribeExpense();
        // };
    }, [])
    //useEffect from data fetching
    useEffect(() => {
        const categoryRef = collection(db, "books", userData.bookList[0], "categories");
        const unSubscribeCategory = onSnapshot(categoryRef, (snapshot) => {
            const categoryData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
                // expenseList: groupByCategory[doc.id],
            }));
            console.log("categoryData change")
            setCategoryList(categoryData);
        })

        return () => {
            unSubscribeCategory();
        }
    }, []);

    const onViewSelector = () => {
        setIsCategory(prev => !prev)
    }

    return (
        <MainContainer>
            <Header>
                <Period>
                    Total expense ({monthDescribe} 1 - Today)
                </Period>
                <TotalAmount>
                    €0
                </TotalAmount>
                <Link to={"/addexpense"}>
                    <AddExpense>
                        Add expense
                    </AddExpense>
                </Link>
            </Header>
            <ViewContainer>
                <ViewSelectorContainer>
                    <ViewTitle>
                        {isCategory ? "Your categories" : "Your expense"}
                    </ViewTitle>
                    <ViewSelector onClick={onViewSelector}>
                        <SelectorIcon src={isCategory ? ListViewIcon : CategoryViewIcon} />
                        <SelectorTitle>
                            {isCategory ? "List view" : "Category view"}
                        </SelectorTitle>
                    </ViewSelector>
                </ViewSelectorContainer>
                {isCategory ? (<CategoryView categoryList={categoryList} groupByCategory={groupByCategory} />) : (<ListView expenseData={expenseData} categoryList={categoryList} />)}

            </ViewContainer>
        </MainContainer>
    )
}

export default Home;