import styled from "styled-components";

const LTitle = styled.span`
    color: ${(props) => props.theme.palette.blue};
    font-family: 'Work Sans', sans-serif;
    font-style: italic;
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    margin-top: ${props => props.marginTop};
    margin-bottom: ${props => props.marginBottom};

`;
export const LogoTitle = ({ marginTop, marginBottom }) => (
    <LTitle marginTop={marginTop} marginBottom={marginBottom}>Our-Expense</LTitle>
)

export const ExtraMessage = styled.div`
    width: 490px;
    text-align: center;
    color: #fff;
    margin-top: 40px;
    font-size: 15px;
    span{
        font-weight: 700;
    }
`

export const SmallTitle = styled.div`
        width: 100%;
        font-size: 15px;
        font-weight: 700;
        color: ${props => props.theme.palette.black800};
        margin-bottom: 16px;
        text-align: left;
    `;

export const MediumTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    color: ${props => props.theme.palette.black800};
    margin-bottom: 16px;
    width:100%;
`;
export const Description = styled.div`
    width:100%;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: ${props => props.theme.palette.black600};
    margin-bottom: 20px;
`;
