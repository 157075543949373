import React from "react";
import styled from "styled-components";
import googleLogo from "../../asset/image/google_logo.svg"
import appleLogo from "../../asset/image/apple_logo.svg"


const Blue = styled.button`
    width: 100%;
    height: 53px;
    border: 0px;
    border-radius: 8px;
    margin-bottom: ${props => props.marginBottom};
    cursor: pointer;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: -0.5px;
    color: #fff;
    background-color: ${props => props.theme.palette.blue};
`;
const Google = styled.div`
    width: 100%;
    height: 57px;
    border: 1px solid #94A3B8;
    border-radius: 8px;
    margin-bottom: ${props => props.marginBottom};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 15px;
    color: ${props => props.theme.palette.black900};
    background-color: #fff;
    img {
        width: 25px;
        margin-right: 15px;

    }
    span {
        font-size: 15px;
        font-weight: 400;
        color: ${props => props.theme.palette.black900};
    }
`;
const Apple = styled(Google)`
    background-color: black;
`
const Resend = styled.div`
    padding: 8px 8px;
    border: 0px;
    border-radius: 8px;
    margin-bottom: ${props => props.marginBottom};
    cursor: pointer;
    font-size: 18px;
    letter-spacing: -0.5px;
    color: #fff;
    background-color: black;
`

//Export 
export const BlueButton = ({ title, marginBottom, onClick }) => (
    <Blue marginBottom={marginBottom} onClick={onClick}>{title}</Blue>
);

export const GoogleButton = ({ marginBottom, onClick }) => (
    <Google marginBottom={marginBottom} onClick={onClick} >
        <img src={googleLogo} alt="google logo" />
        <span>Continue with Google</span>
    </Google>
);

export const AppleButton = ({ marginBottom }) => (
    <Apple marginBottom={marginBottom}>
        <img src={appleLogo} alt="apple logo" />
        <span>Continue with Apple</span>
    </Apple >
);

export const ResendButton = ({ marginBottom }) => (
    <Resend marginBottom={marginBottom} >
        Resend
    </Resend>
)