import React from "react";
import styled from "styled-components";
import MainContainer from "../Components/MainContainer";
import { Link } from "react-router-dom";
import ListView from "../Components/ListView";
import BackButton from "../Components/BackButton";

const Header = styled.div`
    width: 100%;
`;
const CategoryContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;
`;
const CategoryIcon = styled.div`
    background-color: #F1F1F1;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    border-radius: 50%;
    margin-right: 16px;
`;
const CategorySelectorContainer = styled.div`

`;
const CategorySelectorBtn = styled.div`
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
`;
const CategorySelector = styled.div`
`;
const TotalExpense = styled.div`

`;
const AddExpense = styled.div`
    background-color: ${props => props.theme.palette.blue};
    border-radius: 8px;
    border: hidden;
    padding: 16px 0px;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    color: white;
    width: 100%;
`
const ListViewContainer = styled.div`
    margin-top: 24px;
`;

const CategoryDetail = () => {

    return (
        <MainContainer>
            <BackButton title={"Back"} />
            <Header>
                <CategoryContainer>
                    <CategoryIcon>
                        🛒
                    </CategoryIcon>
                    <CategorySelectorContainer>
                        <CategorySelectorBtn>
                            Supermarket
                        </CategorySelectorBtn>
                        <CategorySelector>
                        </CategorySelector>
                        <TotalExpense>
                            Total expense €623,90 (1 Mar-Today)
                        </TotalExpense>
                    </CategorySelectorContainer>
                </CategoryContainer>

                <Link to={"/addexpense"}>
                    <AddExpense>
                        Add expense
                    </AddExpense>
                </Link>
            </Header>
            <ListViewContainer>
                <ListView />
            </ListViewContainer>
        </MainContainer>
    )
}

export default CategoryDetail;