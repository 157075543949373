import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainContainer from "../Components/MainContainer";
import BackButton from "../Components/BackButton";
import AddIconImg from "../asset/image/add-icon.png"
import CategoryAddBox from "../Components/CategoryAddBox";
import ShadowScreen from "../Components/ShadowScreen";
import { db } from "../firebaseConfig";
import { userState } from "../atoms";
import { useRecoilValue } from "recoil";
import EditCategoryItem from "../Components/EditCategoryItem";


const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
`;
const Title = styled.div`
    font-weight: 700;
    font-size: 20px;
`
const AddCategoryBtn = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    span{
        font-size: 15px;
        font-weight: 400;
    }
`;
const AddIcon = styled.img`
    width:15px;
    height: 15px;
    margin-right: 5px;
`;
const CategoryList = styled.ul`
`;


const EditCategory = () => {

    const [isPopup, setIsPopup] = useState(false);
    const [isShadow, setIsShadow] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [selected, setSelected] = useState("");

    const userData = useRecoilValue(userState);

    const categoryRef = db.collection(db.db, "books", userData.bookList[0], "categories");

    const onClickAdd = () => {
        setIsPopup(true)
    }

    const onClickExecute = async () => {
        const selectedRef = db.doc(categoryRef, selected.id);
        await db.deleteDoc(selectedRef);
        setIsShadow(false);
    }


    useEffect(() => {
        const unSubscribeCategory = db.onSnapshot(categoryRef, (snapshot) => {

            const categoryData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }))
            setCategoryList(categoryData);
        })

        return () => {
            unSubscribeCategory();
        }
    }, [])


    return (
        <MainContainer>
            <CategoryAddBox isPopup={isPopup} setIsPopup={setIsPopup} />
            <ShadowScreen isShadow={isShadow} setIsShadow={setIsShadow} excute={"Remove"} onClickExecute={onClickExecute}
                message={`Are you sure you want to remove ${selected?.title} category?`}
                description={"This will remove this category permanently. You cannot restore it. "}
            />
            <BackButton title="Back to list" />
            <Header>
                <Title>Edit Category</Title>
                <AddCategoryBtn onClick={onClickAdd}>
                    <AddIcon src={AddIconImg} />
                    <span>Add category</span>
                </AddCategoryBtn>
            </Header>
            <CategoryList>
                {categoryList && categoryList.length > 0 && (
                    categoryList.map((category) => {
                        return (
                            <EditCategoryItem key={category.id} setIsShadow={setIsShadow} category={category} categoryRef={categoryRef} setSelected={setSelected} />
                        )
                    })
                )}
            </CategoryList>
        </MainContainer>
    )
}

export default EditCategory;