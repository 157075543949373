import React, { useState } from "react";
import styled from "styled-components";
import MainContainer from "../Components/MainContainer";
import BackButton from "../Components/BackButton";
import ModifyIconImg from "../asset/image/modify-icon.png"
import DeleteIconImg from "../asset/image/delete-icon.png"
import ShadowScreen from "../Components/ShadowScreen";
import { useHistory } from "react-router-dom";

const CategoryTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`;
const CategoryIcon = styled.div`
    background-color: #F1F1F1;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;
    margin-right: 12px;
`;
const CategoryText = styled.div`
    font-size: 18px;
    font-weight: 700;
`;
const UtilContainer = styled.div`
    padding: 5px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;
const Amount = styled.div`
    font-size: 42px;
    font-weight: 700;
`;
const ButtonContainer = styled.div`
    display: flex;
`;
const UtilBtn = styled.div`
    background-color: #F1F1F1;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
`;
const ModifyCategoryBtn = styled(UtilBtn)`
    margin-right: 16px;
    background-image: url(${ModifyIconImg});
    background-size: 15px 15px;
`;
const DeleteCategoryBtn = styled(UtilBtn)`
    background-image: url(${DeleteIconImg});
    background-size: 12px 15px;
`;
const InfoContainer = styled.div`
    margin-bottom: 20px;
    display: flex;
`;
const Title = styled.span`
    font-size: 18px;
    font-weight: 700;
    margin-right: 43px;
`;
const Information = styled.span`
    margin-right: 15px;
    font-size: 18px;
    font-weight: 400;
`

const ExpenseDetail = () => {

    const [isShadow, setIsShadow] = useState(false);

    const history = useHistory();
    const onClickModify = () => {
        history.push("edit-expense")
    }
    const onClickDelete = () => {
        setIsShadow(true);
    }

    return (
        <MainContainer>
            <ShadowScreen isShadow={isShadow} setIsShadow={setIsShadow} excute={"Delete"}
                message={"Are you sure you want to delete this?"}
                description={"This will remove this expense permanently. You cannot restore it.  "}
            />
            <BackButton title={"Back to list"} />
            <CategoryTitle>
                <CategoryIcon>🛒</CategoryIcon>
                <CategoryText>Supermarket</CategoryText>
            </CategoryTitle>
            <UtilContainer>
                <Amount>€18.50</Amount>
                <ButtonContainer>
                    <ModifyCategoryBtn onClick={onClickModify} />
                    <DeleteCategoryBtn onClick={onClickDelete} />
                </ButtonContainer>
            </UtilContainer>
            <InfoContainer>
                <Title>Date</Title>
                <Information>
                    4 April 2023 (Today)
                </Information>
            </InfoContainer>
            <InfoContainer>
                <Title>Note</Title>
                <Information>
                    Rewe
                </Information>
            </InfoContainer>
        </MainContainer>
    )
}

export default ExpenseDetail;