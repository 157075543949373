import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { BlueButton, GoogleButton } from "../Components/signPage/Button";
import { Input } from "../Components/signPage/Input";
import { BlueContainer, Contour, FormContainer } from "../Components/signPage/Form";
import { ExtraMessage, LogoTitle } from "../Components/signPage/TextStyle";
import { auth } from "../firebaseConfig";
import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithRedirect } from "firebase/auth";

const ErrorMessage = styled.div`
    width: 100%;
    padding: 8px;
    color: red;
    background: #F3F4F6;
    font-size: 13px;
    font-weight: 400;
    display: ${props => props.error ? "flex" : "none"};
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 7px;
`
const ForgotLink = styled(Link)`
    font-weight: 400;
    margin-bottom: 16px;
`;

const SignIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const onChange = (event) => {
        const { target: { name, value } } = event;
        if (name === "email") {
            setEmail(value);
        } else if (name === "password") {
            setPassword(value);
        }
    }
    const onEmailSubmit = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            console.log("Sign in");
        } catch (error) {
            switch (error.code) {
                case 'auth/wrong-password':
                    setErrorMessage("⚠️ Wrong Password. Please check your password");
                    break;
                case "auth/user-disabled":
                    setErrorMessage("⚠️ Your email has been disabled");
                    break;
                case "auth/user-not-found":
                    setErrorMessage("⚠️ there is no user corresponding to the given email. Please Sign up");
                    break;
                case "auth/invalid-email":
                    setErrorMessage("⚠️ the email address is not valid.");
                    break;
                default:
                    setErrorMessage("⚠️ Too many try in short time. please try later.");
            }
            setIsError(true);
        }
    }
    const onGoogle = async () => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithRedirect(auth, provider);
            console.log("Google Sign up complete");

        } catch (error) {
            setErrorMessage(error.code)
            console.log(error);

            setIsError(true);
        }
    }

    return (
        <BlueContainer>
            <FormContainer>
                <LogoTitle marginTop="62px" marginBottom={"16px"} />
                <ErrorMessage error={isError}>
                    {errorMessage}
                </ErrorMessage>
                <form onSubmit={onEmailSubmit} >
                    <Input name="email" type="email" value={email}
                        placeholder="Email address" required
                        onChange={onChange} error={isError}
                    />

                    <Input name="password" type="password" value={password}
                        placeholder="Password" required
                        onChange={onChange} error={isError} />
                    <BlueButton type="submit" value="signin" title="SignIn" marginBottom="16px" />
                </form>
                <ForgotLink to="forgotpassword">Forgot password?</ForgotLink>
                <Contour />
                <GoogleButton marginBottom="43px" onClick={onGoogle} />

            </FormContainer>
            <ExtraMessage>Don't have an account? <Link to="signup"><span>Sign up</span></Link></ExtraMessage>
        </BlueContainer>
    )
}

export default SignIn;