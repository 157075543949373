import styled from "styled-components";

export const Input = styled.input`
width: 100%;
    border: hidden;
    border-bottom: 2px solid ${props => props.error ? "red" : props.theme.palette.blue};
    color: ${props => props.error ? "red" : "black"};
    margin-bottom: 16px;
    font-size: 15px;
    font-weight: 400;
    outline: none;
    padding-top: 18px;
    padding-bottom: 4px;
    &::placeholder {
        color: ${props => props.theme.palette.black400};
        font-weight: 400;
        font-size: 15px;
    }
    &:focus::placeholder {
        opacity: 0;
    }
`