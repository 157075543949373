import { atom, selector } from "recoil";

export const userState = atom({
    key: "user_info",
    default: null,
});
export const categoryState = atom({
    key: "category_list",
    default: null,
})

export const userSelector = selector({
    key: "filterUser",
    get: ({ get }) => {
        return get(userState);
    },
    set: ({ set }, newValue) => {
        set(userState, newValue);
    }
});
