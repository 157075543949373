import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const ListViewContainer = styled.div`

`
const DailyContainer = styled.ul`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    li:nth-child(2){
        border-top: 1px solid ${props => props.theme.palette.black300};
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
    li:last-child{
        border-bottom: 1px solid ${props => props.theme.palette.black300};
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
`
const DateTitle = styled.h3`
    color: ${props => props.theme.palette.black900};
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
`
const ExpenseList = styled.li`
    padding: 12px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid ${props => props.theme.palette.black300};
    border-right: 1px solid ${props => props.theme.palette.black300};
    border-bottom: 1px solid ${props => props.theme.palette.black300};
    cursor: pointer;
`;
const CategoryContainer = styled.div`
    display: flex;
`
const CategoryIcon = styled.div`
    background-color: #F1F1F1;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;
    margin-right: 12px;
`;
const CategoryText = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const CategoryTitle = styled.div`
    font-size: 18px;
    font-weight: 400;
    color: ${props => props.theme.palette.black900};
    margin-bottom: 2px;
`
const Description = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: ${props => props.theme.palette.black500};
`
const Amount = styled.div`
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.5px;
    color: ${props => props.theme.palette.black900};
`


const ListView = ({ expenseData, categoryList }) => {
    const history = useHistory();
    const onClickDetail = () => {
        history.push("/expense-detail")
    }

    const today = new Date().toLocaleDateString('en-US');
    const yesterday = new Date(Date.now() - 86400000).toLocaleDateString('en-US');

    return (
        <ListViewContainer>

            {expenseData && expenseData.map(([date, expenseList]) => {
                if (date === today) {
                    date = "Today";
                } else if (date === yesterday) {
                    date = "Yesterday";
                } else {
                    date = new Date(date).toLocaleDateString("en-US", { month: "short", day: "numeric" })
                }
                return (
                    <DailyContainer key={date}>
                        <DateTitle>{date}</DateTitle>
                        {expenseList.length > 0 && expenseList.map((expense) => {
                            const [category] = categoryList.filter(category => category.id === expense.category);
                            return (
                                <ExpenseList onClick={onClickDetail} key={expense.id}>
                                    <CategoryContainer>
                                        <CategoryIcon>{category.emoji}</CategoryIcon>
                                        <CategoryText>
                                            <CategoryTitle>
                                                {category.title}
                                            </CategoryTitle>
                                            <Description>
                                                {expense.note}, {expense.date.toDate().toLocaleDateString("en-US", { month: "short", day: "numeric" })}
                                            </Description>
                                        </CategoryText>
                                    </CategoryContainer>
                                    <Amount>
                                        €{expense.amount}
                                    </Amount>
                                </ExpenseList>
                            )
                        })}
                    </DailyContainer>
                )
            })

            }

        </ListViewContainer>
    )
}

export default ListView;