import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Avatar from "../asset/image/blue_avatar.png"
import LogoutButton from "../asset/image/logoutButton.svg"
import { auth } from "../firebaseConfig";
import { signOut } from "firebase/auth";
import AuthContext from "../Context/AuthContext";
import { useRecoilState } from "recoil";
import { userState } from "../atoms";

const Container = styled.div`
    width: 100%;
    padding: 40px 40px 25px 40px;
    position: sticky;
    top: 0;
    background: #FFF;
    box-shadow: ${props => props.isScroll ? "0px 4px 20px rgba(0, 0, 0, 0.15)" : "none"};
    z-index: 3;
`;
const InnerContainer = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
`;
const Logo = styled.h1`
    font-family: 'Work Sans';
    font-style: italic;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: ${props => props.theme.palette.blue};
`;
const MenuContainer = styled.ul`
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
`;
const Menu = styled.li`

`;
const Profile = styled.div`
    display: flex;
    align-items: center;
`
const Image = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 12px;
`;
const UserName = styled.span`
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    margin-right: 27px;
`;
const Logout = styled.div`
    background-image: url(${LogoutButton});
    background-size: cover;
    width: 18px;
    height: 18px;
    cursor: pointer;
`;


const Navigation = () => {
    const [isScroll, setIsScroll] = useState(false);
    const [userData, setUserData] = useRecoilState(userState);
    const onLogOut = () => {
        setUserData(null);
        signOut(auth);
    };
    const { user } = useContext(AuthContext);

    useEffect(() => {
        window.onscroll = () => {
            if (window.scrollY === 0) {
                setIsScroll(false)
            } else {
                setIsScroll(true)
            }
        }

        return () => (window.onscroll = null);
    })

    return (
        <Container isScroll={isScroll} >
            <InnerContainer>
                <Logo>Our-Expense</Logo>
                <MenuContainer>
                    <Menu></Menu>
                    <Menu></Menu>
                </MenuContainer>
                <Profile>
                    <Image src={Avatar} alt="avatar" />
                    <UserName>{userData.displayName || userData.email}</UserName>
                    <Logout onClick={onLogOut} />
                </Profile>
            </InnerContainer>
        </Container>
    )
}

export default Navigation;