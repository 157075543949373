import React from "react";
import styled from "styled-components";
import CancelBoxIcon from "../asset/image/cancel-icon.png"


const ShadowContainer = styled.div`
    width:100%;
    height:100%;
    position: fixed;
    top: 0; //navigation height
    left: 0;
    z-index: 5;
    background-color: rgba(0,0,0,0.4);
    display: ${props => props.isShadow ? "flex" : "none"};
    justify-content: center;
    align-items: center;
`;
const CancelBox = styled.div`
    width: 418px;
    background-color: white;
    position: relative;
    padding: 50px 51px 30px 46px;
`;
const CancelIcon = styled.div`
    background-image: url(${CancelBoxIcon});
    background-size: cover;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
`
const Message = styled.div`
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: 700;
    color: ${props => props.theme.palette.black900};
`;
const Description = styled.div`
    font-size: 15px;
    font-weight: 400;
    color: ${props => props.theme.palette.black900};

`;
const ButtonContainer = styled.div`
    display: flex;
    gap: 9px;
    margin-top:24px;
`;
const ExcuteButton = styled.div`
    background-color: red;
    color: white;
    display: flex;
    justify-content: center;
    padding: 16px 24px;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
`
const CancelButton = styled(ExcuteButton)`
    background-color: ${props => props.theme.palette.black200};
    color: ${props => props.theme.palette.black800};
    font-weight: 400;
`

const ShadowScreen = ({ isShadow, setIsShadow, excute, message, description, onClickExecute }) => {

    return (
        <ShadowContainer isShadow={isShadow}>
            <CancelBox>
                <CancelIcon onClick={() => setIsShadow(false)} />
                <Message>
                    {message}
                </Message>
                <Description>{description}</Description>
                <ButtonContainer>
                    <CancelButton onClick={() => setIsShadow(false)}>Cancel</CancelButton>
                    <ExcuteButton onClick={onClickExecute}>{excute}</ExcuteButton>
                </ButtonContainer>
            </CancelBox>
        </ShadowContainer>
    )
}

export default ShadowScreen;