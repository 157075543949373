import React, { useState } from "react";
import styled from "styled-components";
import ModifyIconImg from "../asset/image/modify-icon.png"
import DeleteIconImg from "../asset/image/delete-icon.png"
import EmojiPicker from "../Components/EmojiPicker";
import { db } from "../firebaseConfig";

const CategoryItemContainer = styled.div`
`
const CategoryItemBox = styled.div`
    border: 1px solid ${props => props.theme.palette.black200};
    border-radius: 12px;
    margin-bottom: 12px;
`
const CategoryItem = styled.div`
    padding: 16px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const CategoryTitle = styled.div`
    display: flex;
    align-items: center;
`;
const CategoryIcon = styled.div`
    background-color: #F1F1F1;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;
    margin-right: 16px;
`;
const CategoryText = styled.div`
    font-size: 18px;
    font-weight: 400;
`;
const ButtonContainer = styled.div`
    display: flex;
`;
const UtilBtn = styled.div`
    background-color: #F1F1F1;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
`;
const ModifyCategoryBtn = styled(UtilBtn)`
    margin-right: 16px;
    background-image: url(${ModifyIconImg});
    background-size: 15px 15px;
`;
const DeleteCategoryBtn = styled(UtilBtn)`
    background-image: url(${DeleteIconImg});
    background-size: 12px 15px;
`;
const CategoryModifyContainer = styled.div`
    margin-top: 16px;
    padding: 0 0 24px 20px;
  
`;
const IconModifyContainer = styled.div`
    margin-bottom: 17px;
    display: flex;
    align-items: center;
    position: relative;
`;
const TitleModifyContainer = styled.div`
    display: flex;
`;
const InputTitle = styled.div`
    width: 158px;
    font-size: 18px;
    font-weight: 700;
`;
const InputEmoji = styled(CategoryIcon)`
    margin-right: 0;
    cursor:pointer;
`;
const InputText = styled.input`
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 400;
    ::placeholder{

    }
`
const ModifyButtonContainer = styled.div`
    display: flex;
    gap: 20px;
`;
const SaveButton = styled.div`
    background-color: ${props => props.theme.palette.black700};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
`
const CancelButton = styled(SaveButton)`
    background-color: ${props => props.theme.palette.black200};
    color: ${props => props.theme.palette.black800};
    font-weight: 400;
`

const EditCategoryItem = ({ category, categoryRef, setSelected, setIsShadow }) => {
    const [isModify, setIsModify] = useState(false);
    const [currentEmoji, setCurrentEmoji] = useState(category.emoji);
    const [currentTitle, setCurrentTitle] = useState(category.title);
    const [isEmojiSelector, setIsEmojiSelector] = useState(false);

    const onClickModify = () => {
        setIsModify(true)
    }
    const onClickCancel = () => {
        setIsModify(false);
        setCurrentEmoji(category.emoji);
        setCurrentTitle(category.title);
    }
    const onChangeTitle = (e) => {
        const { target: { value } } = e;
        setCurrentTitle(value);
    }
    const onClickEmoji = () => {
        setIsEmojiSelector(true)
    }
    const onClickDelete = () => {
        setIsShadow(true)
        setSelected(category);
    }
    const onClickSave = async () => {
        const docRef = db.doc(categoryRef, category.id);
        await db.updateDoc(docRef, {
            title: currentTitle,
            emoji: currentEmoji,
        })
        setIsModify(false);
    }

    return (
        <CategoryItemContainer>
            <CategoryItemBox>
                <CategoryItem>
                    <CategoryTitle>
                        <CategoryIcon>{category.emoji}</CategoryIcon>
                        <CategoryText>{category.title}</CategoryText>
                    </CategoryTitle>
                    <ButtonContainer>
                        <ModifyCategoryBtn onClick={onClickModify} />
                        <DeleteCategoryBtn onClick={onClickDelete} />
                    </ButtonContainer>
                </CategoryItem>
                {isModify &&
                    (<CategoryModifyContainer>
                        <IconModifyContainer>
                            <InputTitle>Icon</InputTitle>

                            <InputEmoji onClick={onClickEmoji}>{currentEmoji}</InputEmoji>
                            <EmojiPicker setEmoji={setCurrentEmoji} isEmojiSelector={isEmojiSelector}
                                setIsEmojiSelector={setIsEmojiSelector}
                            />

                        </IconModifyContainer>
                        <TitleModifyContainer>
                            <InputTitle>Category name</InputTitle>
                            <InputText
                                type="text"
                                value={currentTitle}
                                onChange={onChangeTitle}
                            />
                        </TitleModifyContainer>
                    </CategoryModifyContainer>)
                }
            </CategoryItemBox>
            {isModify &&
                (<ModifyButtonContainer>
                    <CancelButton onClick={onClickCancel}>Cancel</CancelButton>
                    <SaveButton onClick={onClickSave}>Save</SaveButton>
                </ModifyButtonContainer>)
            }

        </CategoryItemContainer>
    )
}

export default EditCategoryItem;