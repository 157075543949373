import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import BackIcon from "../asset/image/back-icon.png";


const GoBack = styled.div`
    cursor: pointer;
    margin-bottom: 28px;
    span {
        font-size: 18px;
        font-weight: 400;
    }
`;
const Icon = styled.img`
    width: 8px;
    margin-right: 12px;
`

const BackButton = ({ title }) => {
    const history = useHistory();

    const onBackClick = () => {
        history.goBack();
    }
    return (
        <GoBack onClick={onBackClick}>
            <Icon src={BackIcon} />
            <span>{title}</span>
        </GoBack>
    );
}

export default BackButton;