import React from "react";
import styled from "styled-components"
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

const PickerContainer = styled.div`
    position: absolute;
    top: 50px;
    left: 150px;
    height: 300px;
    overflow-y: hidden;
    border-radius: 12px;
    display: ${props => props.isEmojiSelector ? "block" : "none"};
`;

const EmojiPicker = ({ setEmoji, isEmojiSelector, setIsEmojiSelector }) => {

    return (
        <PickerContainer isEmojiSelector={isEmojiSelector}>
            <Picker style={{ width: 20, height: 40, }} data={data} maxFrequentRows={0}
                onEmojiSelect={
                    (e, PointerEvent) => {
                        PointerEvent.stopPropagation();
                        setEmoji(e.native)
                        setIsEmojiSelector(false);
                    }
                }
                onClickOutside={() => {
                    if (isEmojiSelector) {
                        setIsEmojiSelector(false);
                    }
                }}
                previewPosition={"none"}
            />
        </PickerContainer>
    )
}

export default EmojiPicker;